jQuery(document).ready(function($) {

  $('#dellin-button').on('click', function () {
    $('#dellin').trigger('click')
  })
  var isMobile = false;
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;


  if ($('div').is('.about__img')){
    var randomInterval = initInterval();

    $('.icon').hover(function (event) {
      clearInterval(randomInterval);
      activateItemOrRandom(this);
    }, function (item) {
      hideAllHovers();
      randomInterval = initInterval();
    })
  }

  function initInterval() {
    return setInterval(function () {
      activateItemOrRandom();
    }, 3500);
  }

  function hideAllHovers() {
    var items = $('.icon');
    items.removeClass('active');
  }
  autosize($('textarea'));
  function activateItemOrRandom(item) {
    hideAllHovers();
    var items = $('.icon');
    var activeItem = item || items[Math.floor(Math.random() * items.length)];
    $(activeItem).addClass('active');
  }
  if (!isMobile){

    $('#product__photo').zoom();
  }
  $('.compare__links-holder a').on('click', function (e) {
    e.preventDefault();
    if (!$(this).is('.uk-active')){
      $('.compare__links-holder a').removeClass('uk-active');
      $(this).addClass('uk-active')
      if ($(this).is('.all-params')){
        $('.compare-table__row_charact,.compare-table__row_price').fadeIn();
      }else{
        $('.compare-table__row_charact,.compare-table__row_price').fadeOut(function () {
          $('.tpl-state-different').fadeIn();
        });
      }
    }

  })

  jQuery("body").on('click','.delete', function () {
    $('.feedback__input.input-upload').val("");
    jQuery(this).closest('form').find('input[type=file]').val('');
    jQuery(this).closest('form').find(".attach").html('прикрепить файл:');
    jQuery(this).remove();
  });

  var options = {
    beforeSend: function() {
      console.log("beforeSend");
    },
    uploadProgress: function(event, position, total, percentComplete) {
      console.log(percentComplete + '%');
    },
    success: function() {
      console.log('success');
    },
    complete: function(response) {
      response = response.responseText;

      var form = jQuery("#addfiles");

      var r = jQuery(response).find(".warnText").html();
      if (typeof r === 'undefined') {
        var old =form.html();
        form.html('Спасибо, ваш запрос отправлен');
        setTimeout(function () {
          form.html(old);
          $("form").trigger('reset');
          $("#message").html('');
          form.trigger('reset');
        },6000)
      }
      else {
        jQuery("#message").html(r);
      }
    },
    error: function() {
      console.log("Ошибка при загрузке файла");
    }
  };
  jQuery("#addfiles").ajaxForm(options);
  jQuery("#feedback").submit(function (e) {
    e.preventDefault();

    var form = jQuery(this);
    var p = form.serializeArray();
    var a = form.attr("action");

    jQuery.post(a, p, function (response) {

      var r = jQuery(response).find(".warnText").html();
      if (typeof r === 'undefined') {
        form.find(".form-message").removeClass('red');
        var formold = form.html();
        form.html('<h3>Спасибо ваш запрос отправлен</h3>');

        setTimeout(function () {
          form.html(formold);
          form.trigger('reset')
        },1600)
      }
      else {
        form.find(".form-message").removeClass('green')
        form.find(".form-message").addClass('red').html(r);
        $('.form-message').addClass('red')

      }

    });
  })
  function validateItems (form, field) {

    if (field.name == 'f_ContactName'){
      if (field.value==''){
        $('#ajax_order_cont').find('input[name='+field.name+']').addClass('error')
        $('#ajax_order_cont').find('input[name='+field.name+']').after('<span class="error">Заполните поле Ваше имя</span>')
        return 1

      }
    }
    if (field.name == 'f_Phone'){

      if (field.value==''){
        $('#ajax_order_cont').find('input[name='+field.name+']').addClass('error')
        $('#ajax_order_cont').find('input[name='+field.name+']').after('<span class="error">Заполните поле Ваш телефон</span>')
        return 1

      } else if(! /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/.test(field.value) ) {

        $('#ajax_order_cont').find('input[name='+field.name+']').addClass('error')
        $('#ajax_order_cont').find('input[name='+field.name+']').after('<span class="error">Введите номер телефона верно</span>')

        return 1
      } else{
        $('#ajax_order_cont').find('input[name='+field.name+']').removeClass('error')
        $('#ajax_order_cont').find('input[name='+field.name+']').removeClass('error');
        return 0
      }

    }
    return 0
  }

  jQuery("body").on('submit','#nc_netshop_add_order_form', function (e) {



    var form = jQuery(this);
    var p = form.serializeArray();
    var a = form.attr("action");
    var error = 0;
    $('#ajax_order_cont').find('span.error').remove();
    $.each( p, function( key, value ) {
      if (key>8 && value.name !='f_steps[]'){
        error +=validateItems(form, value)
      }

    });
    var agreement =     $('#ajax_order_cont').find('input.input-check');
    if (!agreement.is(':checked')){
      error++

      var labels =     $('#ajax_order_cont').find('input.input-check').closest('.basket-feedback__check');
      labels.after('<span class="error">Для отправки заявки необходимо принять условия обработки персональный данных</span>')

    } else{
      $('.basket-feedback__check + .error').html('')
    }
    if (error) return false
  })


  $('body').on('change','input[type=checkbox]', function () {
    if ($(this).closest('.extra-sort')){
      console.log('sahjdsajhd')
    }
    if (!$(this).prop('checked')){
      $(this).closest('form').find('button').attr("disabled", true);
    } else{
      $(this).closest('form').find('button').attr("disabled", false);
    }
  })
  $('.show__format button').on('click', function () {
    if (!$(this).is('.active')){


        $('.show__format button').removeClass('active')
        $(this).addClass('active');
        if ($(this).attr('data-view')=='plate'){

          $('.catalog-table').removeClass('list').addClass('plate')
          $('.catalog-table__item ').removeClass('uk-animation-fade')
          setTimeout(function () {
            $('.catalog-table__item ').addClass(' uk-animation-fade')
          },300)
          $.cookie('viewcatalog', 'plate', { expires: 30, path: '/' });



        }else{

          $('.catalog-table').removeClass('plate').addClass('list')
          $('.catalog-table__item ').removeClass('uk-animation-fade')
          setTimeout(function () {
            $('.catalog-table__item ').addClass(' uk-animation-fade')
          },300)
          $.cookie('viewcatalog', 'list', { expires: 30, path: '/' });
        }

    }
  })
  $("#formphone").mask("+7 (999) 999-99-99");
  orderUrl = '/orders/add_order.html'
//jQuery('div#order_conteiner').load(orderUrl+' '+ '#ajax_order_cont');
  jQuery('div#order_conteiner').load(orderUrl + ' ' + '#ajax_order_cont', function () {
    $("#phone").mask("+7 (999) 999-99-99");
  })

  $('.product__preview-photo').eq(0).find('a').addClass('active')
  $('.product__preview-photo  a').on('click', function() {

    $('.product__preview-photo a').removeClass('active');
    $(this).addClass('active');
    var thImg = $(this).attr('href');
    $('.product__photo img').attr('src', thImg);
    return false;
  });

  $('.products-item__btn,.product__buy-btn,.tpl-link-cart-add').on('click', function (e) {
    var form = $(e.target).closest('form')
    $.post(form.attr('action'), form.serialize() + '&json=1', null, 'json')
      .done(function(response) {
        processCartResponse(response)
      })
      .fail(function() {
        processCartError()
      })
    return false
  })
  $('.input-holder').on('click', function () {
      $(this).toggleClass('active')
  })
  $('body').on('click','.plus', function () {
    var item = $(this).closest('.product__amount').find('input[name^=qty],input[type=text],input[type=number]');
    var num = item.val();
    item.val(++num)
    if ($('form').is('#ajaxcart')){

      ajaxUpdateCard();
    }
  })
  $('body').on('click','.minus', function () {
    var item = $(this).closest('.product__amount').find('input[name=qty],input[type=text],input[type=number]');
    var num = item.val();
    if (num >1){
      item.val(--num)
    }
    if ($('form').is('#ajaxcart')){
      ajaxUpdateCard();
    }

  })
  c = 'tpl-block-comparision';
  q = '.' + c;
  console.log($(q))
  // if ($(q)[0]) {
  //   node = $(q);
  //   viewport = node.find(q + '-original');
  //   doubler = node.find(q + '-doubler');
  //   doubler = viewport.clone().removeClass(c + '-original').addClass(c + '-doubler');
  //   doubler.find('.product-col-holder').detach();
  //
  //   node.find('.name-col').detach();
  //   doubler.insertAfter(viewport);
  // }
  $(q).each(function () {
    node = $(this);
    viewport = node.find(q + '-original');
    doubler = node.find(q + '-doubler');
    doubler = viewport.clone().removeClass(c + '-original').addClass(c + '-doubler');
    doubler.find('.product-col-holder').detach();

    node.find('.name-col').detach();
    doubler.insertAfter(viewport);
  })
  $(".tpl-block-comparision-original").mCustomScrollbar({
    axis:"x",
    theme:"dark-3",
    advanced:{
      autoExpandHorizontalScroll:true
    },
    callbacks:{
      onCreate:function(){
        $(this).find(".item").css("width",$(this).width());
      },
      onBeforeUpdate:function(){
        $(this).find(".item").css("width",$(this).width());
      }
    }
  });
  $('#catalog-btn').click(function () {

    if (!$(this).is('.uk-active')){
      $('.catalog').fadeIn()
      $('.shadow-hidden').fadeIn('shadow')
    } else{
      $('.catalog').fadeOut()
      $('.shadow-hidden').fadeOut()
    }

    $(this).toggleClass('uk-active', 1000, 'easeOutSine')



  })
  $('.shadow-hidden').on('click', function () {
    if ($('#catalog-btn').is('.uk-active')){

      $('#catalog-btn').trigger('click')
    }
  })
  var qFile = 'ajax.php';
  $('#search-input').on('keyup',function (e) {
    if (e.keyCode!=27){

      var search = $(this).val();
      if (search.length>3) {

        $(this).toggleClass('uk-active', 1000, 'easeOutSine')
        $.ajax({
          url: '/netcat/modules/default/' + qFile,
          dataType: "html",
          data: {
            search: search
          },
          success: function (data) {
            $('.search-result__item-holder').html(data)
            $('.search-result').removeClass('search-result-invisible', 5000, 'easeOutSine')
            $('.shadow-hidden').addClass('shadow')
          }
        });
      }
    } else{
      $('.search-result').addClass('search-result-invisible', 5000, 'easeOutSine')
      $('.shadow-hidden').removeClass('shadow')
    }

  })
  $('.search-result').on('click', function (e) {
    e.stopPropagation()
  })
 $('.close-btn-search,.shadow-hidden').on('click', function () {
   $('.search-result').addClass('search-result-invisible', 5000, 'easeOutSine')
   $('.shadow-hidden').removeClass('shadow')
 })
  var qFile = 'ajax.php';
  var offset = 10;
  $('.btn_news').on('click',function (e) {
    e.preventDefault()

      $.ajax({
        url: '/netcat/modules/default/' + qFile,
        dataType: "html",
        data: {
          news: 1,
          offset: offset
        },
        success: function (data) {

          var count = $(data).filter('div.count');
          console.log(count)
          if (offset+1 >=count[0].innerText){
            $('.btn_news').hide();
          }
          $('#news-ajax').append(data)
          $('#news-ajax').remove('.count')
        }
      });
    offset +=6;
  })

  $('.header-nav__list li').each(function () {
    var list = $(this).find('ul')
    if (list.length > 0) {
      // if (!$(this).is('.about')){
      //   $(this).addClass('uk-parent')
      //   $(this).attr('data-uk-dropdown', '{remaintime:0}')
      //   $(this).attr('aria-haspopup', 'true')
      //   $(this).attr('aria-expanded', 'false')
      // }

    }
  })
  $('.header-nav__list .delivery').on('click','a.delivery', function (e) {
    e.preventDefault()
  })
  $('.extra-search').click(function () {
    if (!$(this).is('.open')){
      $(this).addClass('open')

      $('.extra-sort').slideDown();
    } else {
      $(this).removeClass('open')

      $('.extra-sort').slideUp();
    }
  })

  $('.recall,.about-main__recall .btn_accent').click(function (e) {
    e.preventDefault()
    $('.feedback').slideDown()
  })

  $('.close-btn-fb').click(function () {
    $('.feedback').slideUp()
  })

  $('.location-map').click(function () {
    $('.location-popover').slideDown( function () {
      initMappop();
    })
  })

  $('.close-btn-loc').click(function () {
    $('.location-popover').slideUp()
  })

  $('.header-nav__item.delivery').hover(function (e) {
    console.log('das')
    if (!e.target.closest('.dropdown')){
      if (!$(this).is('.open')){
        $(this).addClass('open')
        $('.dropdown').addClass('uk-animation-slidefade-topp ').show();
      } else{
        $(this).removeClass('open')
        $('.dropdown').removeClass('uk-animation-slidefade-topp').addClass('uk-animation-slidefadeout-topp') ;
      }
    }
  }, function (e) {
    $(this).removeClass('open')
    $('.dropdown').removeClass('uk-animation-slidefade-topp').addClass('uk-animation-slidefadeout-topp'); ;
  })
  $('.header-nav__item_mobile.delivery').on('click','a.delivery',function (e) {

  })
  $('.tpl-link-compare-add').click(function(e){
    e.preventDefault();

    $.ajax({
      url: $(this).attr('href'),
      success: function(result){
        console.log(result)
        var updateHtml = $(result).filter('#float-basket').html()
        console.log(updateHtml);
       $('.float-basket').addClass('uk-animation-slide-right').html(updateHtml)
      }

    })
    $(this).hide().next().show()
  });
  $('body').on('blur','#ajaxcart input',function(e){

    e.preventDefault();
    ajaxUpdateCard();

  });
  function  ajaxUpdateCard () {
    var form = $('#ajaxcart')
    var msg   = form.serialize();
   var url = form.attr('action');
    $.ajax({
      type: 'POST',
      url: url,
      data: msg,
      success: function(result) {

        var cartUpdate = $(result).find('#ajaxcart')

        $('#ajaxcart').html(cartUpdate[0].innerHTML)
      },
      error:  function(xhr, str){
        alert('Возникла ошибка: ' + xhr.responseCode);
      }
    })
  }


  $('body').on('click', function (e) {
    if (!e.target.closest('.dropdown') && !$(e.target).hasClass('delivery')){
      $('.header-nav__item.delivery > a').removeClass('open')
      $('.dropdown').removeClass('uk-animation-slidefade-topp').addClass('uk-animation-slidefadeout-topp');
      $('.header-nav__item_mobile.delivery > a').removeClass('open')
      $('.header-nav__item_mobile.delivery .dropdown').removeClass('uk-animation-slidefade-topp').addClass('uk-animation-slidefadeout-topp').hide();
    }
  })
  var $layer_0 = $('.layer-0'),
    $layer_1 = $('.layer-1'),
    $layer_2 = $('.layer-2'),
    $container = $('body'),
    container_w = $container.width(),
    container_h = $container.height();

  $(window).on('mousemove.parallax', function(event) {
    var pos_x = event.pageX,
      pos_y = event.pageY,
      left  = 0,
      top   = 0;

    left = container_w / 2 - pos_x;
    top  = container_h / 2 - pos_y;

    TweenMax.to(
      $layer_2,
      1,
      {
        css: {
          transform: 'translateX(' + left / 12 + 'px) translateY(' + top / 6 + 'px)'
        },
        ease:Expo.easeOut,
        overwrite: 'all'
      });

    TweenMax.to(
      $layer_1,
      1,
      {
        css: {
          transform: 'translateX(' + left / 4 + 'px) translateY(' + top / 5 + 'px)'
        },
        ease:Expo.easeOut,
        overwrite: 'all'
      });

    TweenMax.to(
      $layer_0,
      10,
      {
        css: {
          transform: 'rotate(' + left / 200 + 'deg)'
        },
        ease: Expo.easeOut,
        overwrite: 'none'
      }
    )
  });


  /**
   * Обработчик ответа на запрос на добавление товара в корзину
   */
  function processCartResponse (response) {
    // Обновим блок «Корзина» на странице
    var totalItems = response.TotalCount
    if (totalItems==0){

      var cartHtml = totalItems
        ? '<a href="/cart/" class="basket-icon">' +
        '<div class="baidge">'+totalItems+'</div>' +
        '</a>' +
        '<a href="/sravnenie/" class="libra"><div class="baidge">1</div></a>'
        : ''
    }
    $('.float-basket').addClass('uk-animation-slide-right');
    $('.float-basket .basket-icon .baidge').html(totalItems)


    // Сообщения о невозможности добавить выбранное количество товара в корзину
    if (response.QuantityNotifications) {
      // Обработка таких сообщений не показана в этом примере для краткости
    }
    // Сообщение о том, что товар успешно добавлен в корзину:
    UIkit.notify("<i class='uk-icon-check'></i> Товар добавлен в корзину");
  }

  /**
   * Обработчик ошибки запроса на добавление товара в корзину
   */
  function processCartError (response) {
    console.log(response)
    UIkit.notify("<i class='uk-alert-danger'></i>Произошла ошибка при добавление в корзину");
  }

  /**
   * Склонение русских слов
   * @param {Number} itemQuantity  Количество товаров
   * @param {String} one           «товар»
   * @param {String} two           «товара»
   * @param {String} many          «товаров»
   * @returns {String}
   */
  function pluralForm (itemQuantity, one, two, many) {
    itemQuantity = Math.abs(itemQuantity) % 100
    var underHundred = itemQuantity % 10,
      result = many

    if (underHundred > 1 && underHundred < 5) { result = two }
    if (underHundred == 1) { result = one }
    if (itemQuantity > 10 && itemQuantity < 20) { result = many }

    return result
  }

  /**
   * Инициализация кнопок «Положить в корзину» (предполагается, что у таких
   * кнопок есть CSS-класс .tpl-link-cart-add).
   */

// В шаблоне используется скрипт magnificParams. Это параметры по умолчанию для него для всплывающих сообщений:
  var magnificParams = {
    items: {},
    mainClass: 'tpl-block-mfp-animating',
    removalDelay: 200,
    type: 'inline',
    closeMarkup: '<div class="tpl-block-mfp-close"><i class="icon-popup-close"></i></div>'
  }

  /**
   * Склонение русских слов
   */
  function pluralForm (itemQuantity, one, two, many) {
    itemQuantity = Math.abs(itemQuantity) % 100
    var underHundred = itemQuantity % 10,
      result = many

    if (underHundred > 1 && underHundred < 5) { result = two }
    if (underHundred == 1) { result = one }
    if (itemQuantity > 10 && itemQuantity < 20) { result = many }

    return result
  }

  function close_all_dropdowns () {
    return $('.tpl-block-dropdown ul').slideUp(150)
  }
  $('.tpl-block-dropdown ul').click(function (e) {
    return e.stopPropagation()
  })
  $('body').click(function () {
    return close_all_dropdowns()
  })

  function initMappop() {
    // Styles a map in night mode.
    var map = new google.maps.Map(document.getElementById('mappop'), {
      center: {lat:55.920772, lng:  37.741608},
      zoom: 13,
      styles: [
        {elementType: 'geometry', stylers: [{color: '#f2f2f2'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#cdcdce'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#cdcdce'}]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{color: '#cdcdce'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{color: '#f2f2f2'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{color: '#f2f2f2'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#cdcdce'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#45bced'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '#cdcdce'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#45bced'}]
        }
      ]
    });
    var marker = new google.maps.Marker({
      position: {lat:55.920772, lng:  37.741608},
      icon:  '/images/newmarker.png',
      map: map
    });
  }


})
